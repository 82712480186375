import {optionsList} from './optionsList.js'
import {gameResult} from './determineWinner.js'
import {logBoard} from './logBoard.js'

export function minimax([boardArray, isMaximizing]) {


  var result = gameResult(boardArray)
  var options  = optionsList(boardArray)

  if (result !== 0) {
    return result
  }
  else if (options === undefined || options.length == 0) {
    return 0;
  }
  else{
    if (isMaximizing) {
      var minimaxArray = options.map(function(option, index){
          var newBoard = boardArray.slice();
          newBoard[option] = 1;

          var maxArray = minimax([newBoard,false])

          if (Array.isArray(maxArray)) {
            return Math.min(...maxArray)
          }
          else {
            return maxArray;
          }
      })
    }
    else {
      var minimaxArray = options.map(function(option, index){

          var newBoard = boardArray.slice();
          newBoard[option] = 2;

          var minArray = minimax([newBoard,true])

          if (Array.isArray(minArray)) {
            return Math.max(...minArray)
          }
          else {
            return minArray;
          }

      })

    }

  }
  

  return minimaxArray
}
