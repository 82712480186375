import React, { useState, useEffect,useRef } from 'react';
import useWindowDimensions from '../../functions/useWindowDimensions';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {Ebin} from './projects';
import {Wegoal, Thefinanceapp, Clickofwar, Fridgenotes, Gymapp, Joygage} from './projects';
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";

export const ProjectList = (props) => {
  const { height, width } = useWindowDimensions();
  const [name, setName] = useState();
  const [viewState, setViewState] = useState(0);

  if (viewState==0) {
    return (
        <div className="project-row">
          <a style={{marginTop:20}} className="large-text">Project List</a>
          <div className="project-col">
            <a className="medium-text">2018</a>
            <div className="project-items" onClick={()=> setViewState(1)}>
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text">  React Native: E-Bin (Published)</a>
            </div>
            <div className="project-items" onClick={()=> setViewState(2)}>
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text"> React Native: We Goal (Minimum Viable Product)</a>
            </div>
            <a style={{marginTop:20}} className="medium-text">2019</a>
            <div className="project-items" onClick={()=> setViewState(3)}>
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text">  React Native: The Finance App (Published)</a>
            </div>
            <div className="project-items" onClick={()=> setViewState(4)}>
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text"> React Native: Click of War (Published)</a>
            </div>
            <div className="project-items" onClick={()=>  window.open('https://go30-8321d.web.app/')}>
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text"> React Native: React Native Code Editor (Try it out)</a>
            </div>
            <a style={{marginTop:20}} className="medium-text">2020</a>
            <div className="project-items" onClick={()=>  window.open('https://morning-earth-11381.herokuapp.com/')}>
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text"> React: Sample Personal Blog</a>
            </div>
            <div className="project-items">
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text"> React: Portfolio</a>
            </div>
            <Link className="project-items" to="/tictac" style={{ textDecoration: 'none', color:'black' }}>
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text"> React: Tic Tac Toe (Minimax Function)</a>
            </Link>
            <div className="project-items" onClick={()=> setViewState(5)}>
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text"> React Native: Fridge Notes (Published)</a>
            </div>
            <div className="project-items" onClick={()=> setViewState(6)}>
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text"> React Native: Gym App (Code Sample)</a>
            </div>
            <div className="project-items" onClick={()=> setViewState(7)}>
              <FiberManualRecordIcon style={{fontSize:8}}/>
              <a className="small-text"> Current: Full Stack Developer (Joygage)</a>
            </div>
          </div>
        </div>
    )
  }
  else {
    return (
      <div className="project-row">
        {viewState==1 ? (<Ebin backButton={()=> setViewState(0)}/>):(null)}
        {viewState==2 ? (<Wegoal backButton={()=> setViewState(0)}/>):(null)}
        {viewState==3 ? (<Thefinanceapp backButton={()=> setViewState(0)}/>):(null)}
        {viewState==4 ? (<Clickofwar backButton={()=> setViewState(0)}/>):(null)}
        {viewState==5 ? (<Fridgenotes backButton={()=> setViewState(0)}/>):(null)}
        {viewState==6 ? (<Gymapp backButton={()=> setViewState(0)}/>):(null)}
        {viewState==7 ? (<Joygage backButton={()=> setViewState(0)}/>):(null)}
      </div>
    )
  }
}
