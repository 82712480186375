import React, { useState, useEffect,useRef } from 'react';
import useWindowDimensions from '../functions/useWindowDimensions';
import { Reorder, ThreeDRotation } from '@material-ui/icons';
import Pdf from "../assets/MichaelMurray_Resume.pdf";
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";


export const Header = (props) => {
  const { height, width } = useWindowDimensions();
  const [name, setName] = useState();

  function onResumeClick() {
    window.open(Pdf);
  }

    return (
      <div className="header-row">
        <div className="header-contact">
          <a className="large-text">Michael Murray</a>
          <a className="small-text">Phone: (313) 910-6492</a>
          <a className="small-text">Email: mfmurray@umich.edu</a>
        </div>
        {width<750 ?
           (<div className="header-titles" onClick={props.openOverlay}>
             <Reorder fontSize="medium" />
            </div>)
          :(<div className="header-titles">
            <Link className="header-title" to="/" style={{ textDecoration: 'none', color:'black',}}>Projects</Link>
            <Link className="header-title" to="/tictac" style={{ textDecoration: 'none', color:'black' }}>TicTac</Link>
            <a className="header-title" onClick={()=> onResumeClick()}>Resume</a>
          </div>)
        }
      </div>
    )
}
