import React, { useState, useEffect,useRef } from 'react';
import './tictac.css';
import {gameResult} from './functions/determineWinner.js';
import {minimax} from './functions/minimax.js';
import {optionsList} from './functions/optionsList.js';
import {logBoard} from './functions/logBoard.js'

export function TicTac() {

  const [boardArray, setBoardArray] = useState([0,0,0,0,0,0,0,0,0,]);
  const [boardString, setBoardString] = useState(['-','-','-','-','-','-','-','-','-',]);
  const [currentState, setCurrentState] = useState(1);
  const [result, setResult] = useState(0);
  const [stalemateScore, setStalemateScore] = useState(0);
  const [lossScore, setLossScore] = useState(0);

  useEffect(() => {
    console.log('start')

    setStalemateScore(localStorage.getItem("stalemateScore"))
    setLossScore(localStorage.getItem("lossScore"))
  }, [])

  function updateBoard(tab) {

    if (boardArray[tab[0]] !== 0 || result) {
        return;
    }

    var newBoard;
    if (tab[1]==1) {
      newBoard = boardArray.slice();
      var newBoardString = boardString.slice();
      newBoard[tab[0]] = 1;
      newBoardString[tab[0]] = 'X';

      logBoard(newBoard)
      setBoardArray(newBoard)
      setBoardString(newBoardString)
    }

    var gameStateResult = gameResult(newBoard)
    if (gameStateResult !== 0) {
      setResult(gameStateResult)
      console.log('gameStateResult ',gameStateResult)
      return;
    }
    if (optionsList(newBoard) === undefined || optionsList(newBoard).length == 0) {
      setResult(10)
      var sCount = localStorage.getItem("stalemateScore")
      if (sCount) {
        localStorage.setItem("stalemateScore", parseInt(sCount)+1);
      }
      else {
        localStorage.setItem("stalemateScore", 1);
      }
      setStalemateScore(localStorage.getItem("stalemateScore"))
      return
    }


    if (tab[1]==1) {

      var minimaxArray = minimax([newBoard,false])
      var options = optionsList(newBoard)
      var computerIndex;
      if (Array.isArray(minimaxArray)) {
        computerIndex = minimaxArray.indexOf(Math.min(...minimaxArray))
      }
      else {
        computerIndex = minimaxArray
      }
      var computerMove = options[computerIndex]
      newBoard[computerMove] = 2;
      newBoardString[computerMove] = 'O';
      setBoardArray(newBoard)
      setBoardString(newBoardString)

      var gameStateResult = gameResult(newBoard)
      if (gameStateResult !== 0) {
        setResult(gameStateResult)
        var lCount = localStorage.getItem("lossScore")
        if (lCount) {
          localStorage.setItem("lossScore", parseInt(lCount)+1);
        }
        else {
          localStorage.setItem("lossScore", 1);
        }
        setLossScore(localStorage.getItem("lossScore"))
        console.log('gameStateResult ',gameStateResult)

        return;
      }
    }


  }

  function resetGame() {
    setBoardArray([0,0,0,0,0,0,0,0,0,])
    setBoardString(['-','-','-','-','-','-','-','-','-',])
    setResult(0)
  }



  return (
    <div className="App">
      <div style={{width:'100vw'}}>
        <div className="reset" onClick={()=> resetGame()}>Reset</div>
      </div>

      <div>Stalemates {stalemateScore}</div>
      <div>Losses {lossScore}</div>
      <div>Wins 0</div>
      {result == 0 ? (<div className="title">{currentState==1 ? "X":"O" } turn</div>):
      (<div className="title">{result==10 ? "Stalemate":"X Lost" }</div>)}


      <div className="row" style={{paddingTop:'20px'}}>
        <div className="block" onClick={()=> updateBoard([0,1])}>{boardString[0]}</div>
        <div className="block" onClick={()=> updateBoard([1,1])}>{boardString[1]}</div>
        <div className="block" onClick={()=> updateBoard([2,1])}>{boardString[2]}</div>
      </div>
      <div className="row">
        <div className="block" onClick={()=> updateBoard([3,1])}>{boardString[3]}</div>
        <div className="block" onClick={()=> updateBoard([4,1])}>{boardString[4]}</div>
        <div className="block" onClick={()=> updateBoard([5,1])}>{boardString[5]}</div>
      </div>
      <div className="row">
        <div className="block" onClick={()=> updateBoard([6,1])}>{boardString[6]}</div>
        <div className="block" onClick={()=> updateBoard([7,1])}>{boardString[7]}</div>
        <div className="block" onClick={()=> updateBoard([8,1])}>{boardString[8]}</div>
      </div>
      <div className="row">
        <div style={{fontSize:'25px', cursor: 'pointer',textDecorationLine: 'underline'}} onClick={()=>  window.open('https://en.wikipedia.org/wiki/Minimax')}>Minimax Algorithm</div>
      </div>
    </div>
  );
}
