import React, { useState, useEffect,useRef } from 'react';
import useWindowDimensions from '../functions/useWindowDimensions';
import { Reorder, ThreeDRotation } from '@material-ui/icons';
import pic_css from '../assets/tools/CSS.png';
import pic_html from '../assets/tools/HTML.png';
import pic_javascript from '../assets/tools/JavaScript.png';
import pic_native from '../assets/tools/native.png';
import pic_redux from '../assets/tools/redux2.png';
import pic_node from '../assets/tools/nodeJS.PNG';
import pic_graphql from '../assets/tools/GraphQL.png';
import pic_mongo from '../assets/tools/MongoDB2.png';
import pic_git from '../assets/tools/GitHub-Mark-120px-plus.png';



export const Footer = (props) => {
  const { height, width } = useWindowDimensions();
  const [name, setName] = useState('Tools');

    return (
    <div className="footer-bottom">
      <div className="footer-title">
        <a className="large-text">{name}</a>
      </div>
      <div className="footer-row">
        <div className="footer-image" onMouseEnter={()=> setName("JavaScript")} onMouseLeave={()=> setName('Tools')}>
          <img src={pic_javascript} className="footer-img"/>
        </div>
        <div className="footer-image" onMouseEnter={()=> setName("HTML")} onMouseLeave={()=> setName('Tools')}>
          <img src={pic_html} className="footer-img"/>
        </div>
        <div className="footer-image" onMouseEnter={()=> setName("CSS")} onMouseLeave={()=> setName('Tools')}>
          <img src={pic_css} className="footer-img"/>
        </div>
        <div className="footer-image" onMouseEnter={()=> setName("React")} onMouseLeave={()=> setName('Tools')}>
          <img src={pic_native} className="footer-img"/>
        </div>
        <div className="footer-image" onMouseEnter={()=> setName("Redux")} onMouseLeave={()=> setName('Tools')}>
          <img src={pic_redux} className="footer-img"/>
        </div>
        <div className="footer-image" onMouseEnter={()=> setName("Node.JS")} onMouseLeave={()=> setName('Tools')}>
          <img src={pic_node} className="footer-img"/>
        </div>
        <div className="footer-image" onMouseEnter={()=> setName("GraphQL")} onMouseLeave={()=> setName('Tools')}>
          <img src={pic_graphql} className="footer-img"/>
        </div>
        <div className="footer-image" onMouseEnter={()=> setName("MongoDB")} onMouseLeave={()=> setName('Tools')}>
          <img src={pic_mongo} className="footer-img"/>
        </div>
        <div className="footer-image" onMouseEnter={()=> setName("GitHub")} onMouseLeave={()=> setName('Tools')}>
          <img src={pic_git} className="footer-img"/>
        </div>
      </div>
    </div>
    )
}
