import React, { useState, useEffect,useRef } from 'react';
import {HashRouter as Router, Link} from "react-router-dom";
import useWindowDimensions from '../functions/useWindowDimensions';
import { Reorder, ThreeDRotation } from '@material-ui/icons';
import Pdf from "../assets/MichaelMurray_Resume.pdf";

export const ReorderView = (props) => {
  const { height, width } = useWindowDimensions();
  const [name, setName] = useState();

  function onResumeClick() {
    props.closeOverlay()
    window.open(Pdf);
  }

    return (
      <div className="reorder-overlay">
        <Link className="reorder-text" to="/" style={{textDecorationLine: 'none', color:'black'}} onClick={props.closeOverlay}>Projects</Link>
        <Link className="reorder-text" to="/tictac" style={{textDecorationLine: 'none', color:'black'}} onClick={props.closeOverlay}>Tic Tac</Link>
        <a className="reorder-text" onClick={()=> onResumeClick()}>Resume</a>
      </div>
    )
}
