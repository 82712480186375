import React, { useState, useEffect,useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import {Home} from './components/home';
import {TicTac} from './components/tictac/tictac';
import {Header} from './components/header';
import {ReorderView} from './components/reorderView';
import {HashRouter as Router, Switch, Route, Link} from "react-router-dom";


function App() {
  const [viewState, setViewState] = useState(0);

  return (
    <Router>
      <header>
      <Header openOverlay={()=> setViewState(1)}/>

        <Switch>
          <Route path="/tictac">
            <TicTac />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </header>
      {viewState==1 ? (<ReorderView closeOverlay={()=> setViewState(0)}/>):(null)}
    </Router>
  );
}

export default App;
