export function optionsList(boardArray) {
  var options = [];
  var i;
  for (i = 0; i < boardArray.length; i++) {
    if (boardArray[i]==0) {
      options.push(i)
    }
  }
  
  return options;
}
