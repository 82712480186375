import React, { useState, useEffect,useRef } from 'react';
import {Header} from './header';
import {ProjectList} from './projects/projectList';
import {Footer} from './footer';
import {ReorderView} from './reorderView';

import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";


export const Home = (props) => {
  


    return (
      <div>
        <div className="triangle-base">
          <div className="triangle"></div>
        </div>

        <ProjectList/>

        <Footer/>



      </div>
    )
}
