import React, { useState, useEffect,useRef } from 'react';
import useWindowDimensions from '../../functions/useWindowDimensions';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import './project.css';
import ebin1 from '../../assets/projects/ebin1.png';
import ebin2 from '../../assets/projects/ebin2.png';
import ebin3 from '../../assets/projects/ebin3.png';
import wegoal from '../../assets/projects/wegoal.PNG';
import finance1 from '../../assets/projects/finance1.png';
import finance2 from '../../assets/projects/finance2.png';
import color1 from '../../assets/projects/color2.png';
import color2 from '../../assets/projects/color3.png';
import fridge1 from '../../assets/projects/screen1_web.png';
import fridge2 from '../../assets/projects/screen2_web.png';
import fridge3 from '../../assets/projects/screen3_web.png';
import gym1 from '../../assets/projects/gym1.png';
import gym2 from '../../assets/projects/gym2.png';
import joy1 from '../../assets/projects/joygage.png';

export const Ebin = (props) => {
  const [name, setName] = useState();
  const [hoverState, setHoverState] = useState(0);
  const [viewState, setViewState] = useState(1);

  return (
    <div className="project-center-view">
      <div className="project-back-view">
        <div className="project-back-button" onClick={props.backButton}>
          <ArrowBackIos/>
          <a className="medium-text">Back</a>
        </div>
      </div>

      <a className="large-text">Project: E-Bin</a>

      <div className="margin-15"/>

      <div>
        <img src={ebin1} className="project-img"/>
        <img src={ebin2} className="project-img"/>
        <img src={ebin3} className="project-img"/>
      </div>

      <div className="project-back-view">
        <div className="margin-15"/>

        <a className="medium-text">Description</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8}}/>
          <a className="small-text"> Add a recycling station to your organization which rewards based on how they recycle.</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Applied</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8}}/>
          <a className="small-text"> Django, Python, React Native, Barcode, and Camera.</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Links</a>
        <div className="project-select"  onClick={()=>  window.open('https://apps.apple.com/us/app/e-bin/id1482865174')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Apple</a>
        </div>
        <div className="project-select" onClick={()=>  window.open('https://play.google.com/store/apps/details?id=com.BottlesforBucks.BottleforBucks&hl=en_US')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Android</a>
        </div>

      </div>

    </div>
  )

}

export const Wegoal = (props) => {
  const [name, setName] = useState();
  const [hoverState, setHoverState] = useState(0);
  const [viewState, setViewState] = useState(1);

  return (
    <div className="project-center-view">
      <div className="project-back-view">
        <div className="project-back-button" onClick={props.backButton}>
          <ArrowBackIos/>
          <a className="medium-text">Back</a>
        </div>
      </div>

      <a className="large-text">Project: We Goal</a>

      <div className="margin-15"/>

      <div>
        <img src={wegoal} className="project-img"/>
      </div>

      <div className="project-back-view">
        <div className="margin-15"/>

        <a className="medium-text">Description</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text">Built the MVP (Post your goal to a wide audience and connect people with similar goals and interests)</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Applied</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text">React Native, Redux, and Persist</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Links</a>
        <div className="project-select"  onClick={()=>  window.open('https://www.wegoalapp.com/')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Website</a>
        </div>

      </div>

    </div>
  )

}

export const Thefinanceapp = (props) => {
  const [name, setName] = useState();
  const [hoverState, setHoverState] = useState(0);
  const [viewState, setViewState] = useState(1);

  return (
    <div className="project-center-view">
      <div className="project-back-view">
        <div className="project-back-button" onClick={props.backButton}>
          <ArrowBackIos/>
          <a className="medium-text">Back</a>
        </div>
      </div>

      <a className="large-text">Project: The Finance App</a>

      <div className="margin-15"/>

      <div>
        <img src={finance2} className="project-img"/>
        <img src={finance1} className="project-img"/>
      </div>

      <div className="project-back-view">
        <div className="margin-15"/>

        <a className="medium-text">Description</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text">A personal budget app with a simple design to quickly add/edit earnings and expenses.</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Applied</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text">React Native, Redux, Persist, Animated, PanResponder, and more...</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Links</a>
        <div className="project-select"  onClick={()=>  window.open('https://apps.apple.com/us/app/id1522026969')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Apple</a>
        </div>
        <div className="project-select" onClick={()=>  window.open('https://play.google.com/store/apps/details?id=com.money.MichaelMurray')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Android</a>
        </div>

      </div>

    </div>
  )

}

export const Clickofwar = (props) => {
  const [name, setName] = useState();
  const [hoverState, setHoverState] = useState(0);
  const [viewState, setViewState] = useState(1);

  return (
    <div className="project-center-view">
      <div className="project-back-view">
        <div className="project-back-button" onClick={props.backButton}>
          <ArrowBackIos/>
          <a className="medium-text">Back</a>
        </div>
      </div>

      <a className="large-text">Project: Click of War</a>

      <div className="margin-15"/>

      <div>
        <img src={color1} className="project-img"/>
        <img src={color2} className="project-img"/>
      </div>

      <div className="project-back-view">
        <div className="margin-15"/>

        <a className="medium-text">Description</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text">Click with/against friends (Tug of war game). Designed to be a social experiment to see if people can come together toward one goal.</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Applied</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text">React Native, Animated, Redux, Persist, and Firebase</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Links</a>
        <div className="project-select"  onClick={()=>  window.open('https://apps.apple.com/us/app/click-of-war/id1507584094')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Apple</a>
        </div>
        <div className="project-select" onClick={()=>  window.open('https://play.google.com/store/apps/details?id=com.go.MichaelMurray')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Android</a>
        </div>

      </div>

    </div>
  )

}

export const Fridgenotes = (props) => {
  const [name, setName] = useState();
  const [hoverState, setHoverState] = useState(0);
  const [viewState, setViewState] = useState(1);

  return (
    <div className="project-center-view">
      <div className="project-back-view">
        <div className="project-back-button" onClick={props.backButton}>
          <ArrowBackIos/>
          <a className="medium-text">Back</a>
        </div>
      </div>

      <a className="large-text">Project: Fridge Notes</a>

      <div className="margin-15"/>

      <div>
        <img src={fridge1} className="project-img"/>
        <img src={fridge2} className="project-img"/>
        <img src={fridge3} className="project-img"/>
      </div>

      <div className="project-back-view">
        <div className="margin-15"/>

        <a className="medium-text">Description</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text">Share all your Reminder, Notes, and Payments on Fridge Notes. Perfect for families, clubs, groups, and organizations.</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Applied</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text">MERN (MongoDB, Express, React Native, and Node.js), Redux, Persist, Animated, and more...</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Links</a>
        <div className="project-select"  onClick={()=>  window.open('https://apps.apple.com/us/app/fridge-notes/id1532345022')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Apple</a>
        </div>
        <div className="project-select" onClick={()=>  window.open('https://play.google.com/store/apps/details?id=com.FridgeNotes.MichaelMurray')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Android</a>
        </div>

      </div>

    </div>
  )

}

export const Gymapp = (props) => {
  const [name, setName] = useState();
  const [hoverState, setHoverState] = useState(0);
  const [viewState, setViewState] = useState(1);

  return (
    <div className="project-center-view">
      <div className="project-back-view">
        <div className="project-back-button" onClick={props.backButton}>
          <ArrowBackIos/>
          <a className="medium-text">Back</a>
        </div>
      </div>

      <a className="large-text">Project: Gym App (Prototype)</a>

      <div className="margin-15"/>

      <div>
        <img src={gym1} className="project-img"/>
        <img src={gym2} className="project-img"/>
      </div>

      <div className="project-back-view">
        <div className="margin-15"/>

        <a className="medium-text">Description</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8}}/>
          <a className="small-text"> Created a Demo app for a gym. App includes (1) Leaderboard (2) Scheduling function (3) Studio Locations and (4) Workout Videos</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Applied</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8}}/>
          <a className="small-text"> React Native, Redux, Persist, Animated, PanResponder, and more...</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Links</a>
        <div className="project-select"  onClick={()=>  window.open('https://github.com/mfmurray/gymDemo')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Github</a>
        </div>

      </div>

    </div>
  )

}

export const Joygage = (props) => {
  const [name, setName] = useState();
  const [hoverState, setHoverState] = useState(0);
  const [viewState, setViewState] = useState(1);

  return (
    <div className="project-center-view">
      <div className="project-back-view">
        <div className="project-back-button" onClick={props.backButton}>
          <ArrowBackIos/>
          <a className="medium-text">Back</a>
        </div>
      </div>

      <a className="large-text">Contract: Full Stack Developer</a>

      <div className="margin-15"/>

      <div>
        <img src={joy1} className="project-img-small"/>
      </div>

      <div className="project-back-view">
        <div className="margin-15"/>

        <a className="medium-text">Description</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8}}/>
          <a className="small-text"> Current Job: Developing an App to improve the life for people with dementia/Alzheimer's. Working as a Full Stack Developer (React, React Native, and NodeJS) to build product.</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Application</a>
        <div className="project-text">
          <FiberManualRecordIcon style={{fontSize:8}}/>
          <a className="small-text"> React, React Native, Redux, NodeJS, GraphQL, and more...</a>
        </div>

        <div className="margin-15"/>

        <a className="medium-text">Links</a>
        <div className="project-select"  onClick={()=>  window.open('https://www.joygage.com/')}>
          <FiberManualRecordIcon style={{fontSize:8, marginRight:5}}/>
          <a className="small-text" style={{textDecorationLine: 'underline'}}>Joygage</a>
        </div>

      </div>

    </div>
  )

}
